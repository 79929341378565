<template>
  <div class="modal__role">
    <div class="modal__content">
      <img class="role__icon" :src="`./role_${getActiveGame - 1}.png`" alt="" />
      <p class="modal__text">
        Вітаємо! Ви подолали один із етапів гри, а ваш показник безпеки —
        <span>{{ getSecurityPointsRound }}</span
        >. Далі — складніше, але й цікавіше!
      </p>
      <ButtonBase @click="next" class="modal__control"> Продовжити </ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getFromLS, isLSHasItem, setToLS } from "@/library/helpers";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("modal", ["getModalProps"]),
    ...mapGetters([
      "getActiveGame",
      "getActiveRound",
      "getSecurityPoints",
      "getSecurityPointsRound",
    ]),
  },
  created() {},
  methods: {
    next() {
      if (isLSHasItem("game") && this.getActiveRound == 0) {
        let gameData = getFromLS("game");
        let gameDataArray = gameData.split("-");
        gameDataArray[3] = 0;
        this.$store.commit("SET_SECURITY_POINTS_ROUND", 0);
        gameData = [
          gameDataArray[0],
          gameDataArray[1],
          gameDataArray[2],
          gameDataArray[3],
        ].join("-");
        console.log(gameData);
        setToLS("game", gameData);
      }
      this.$store.commit("modal/SET_MODAL", {
        state: false,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4.8rem 3.6rem 6.4rem
  text-align: center
  @include m
    padding: 3.2rem 1.6rem


.modal__text
  margin-bottom: 3.2rem
  & span
    font-size: 2.4rem
    font-weight: 700

input
  background: #FFFFFF
  box-shadow: inset 1px 2px 16px #F4F4F4
  padding: 1.6rem
  width: 100%
  max-width: 32rem
  margin-bottom: 3.2rem

.input::placeholder
  font-weight: 500
  font-size: 1.4rem
  color: $text-primary-color

.name__title
  font-weight: 700
  margin-bottom: 2.4rem

.modal__control
  width: 100%
  max-width: 32rem

.role__icon
  max-width: 6.4rem
  max-height: 6.4rem
  margin-bottom: 1.6rem

.security__number
  margin-bottom: 1.6rem
  & span
    margin-left: 0.8rem
    font-size: 2.4rem
    font-weight: 700
</style>
