<template>
  <div class="modal__role">
    <div class="modal__content">
      <p class="security__number">
        Загальний рівень безпеки:
        <span>{{ getSecurityPoints }} / 150</span>
      </p>
      <p v-if="getSecurityPoints <= 50" class="modal__text">{{ results[0] }}</p>
      <p
        v-if="getSecurityPoints > 50 && getSecurityPoints <= 100"
        class="modal__text"
      >
        {{ results[1] }}
      </p>
      <p
        v-if="getSecurityPoints > 100 && getSecurityPoints <= 140"
        class="modal__text"
      >
        {{ results[2] }}
      </p>
      <p v-if="getSecurityPoints > 140" class="modal__text">
        {{ results[3] }}
      </p>
      <div class="modal__control">
        <ButtonBase @click="next" class="modal__control">
          Зіграти ще раз
        </ButtonBase>
        <ButtonBase @click="next" class="modal__control">
          Ознайомитись з джерелами
        </ButtonBase>
        <p>Розповісти про гру друзям</p>
        <div class="social">
          <ShareNetwork
            network="facebook"
            url="https://www.demogratia-2.platfor.ma/"
            title="Я пройшов «Демогратію: особиста безпека під час війни» і тобі раджу спробувати"
            description="Я пройшов «Демогратію: особиста безпека під час війни» і тобі раджу спробувати"
          >
            <img class="facebook-icon" src="facebook.png" />
          </ShareNetwork>
          <ShareNetwork
            network="twitter"
            url="https://www.demogratia-2.platfor.ma/"
            title="Я пройшов «Демогратію: особиста безпека під час війни» і тобі раджу спробувати"
            description="Я пройшов «Демогратію: особиста безпека під час війни» і тобі раджу спробувати"
          >
            <img class="twitter-icon" src="twitter.png" />
          </ShareNetwork>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setToLS } from "@/library/helpers";

export default {
  data() {
    return {
      results: [
        "Погано. На жаль, ви погано обізнані в тому, як поводити себе в умовах повномасштабної війни. Цим ви наражаєте на небезпеку і себе, і людей навколо. Благаємо: пройдіть гру ще раз і почитайте сторінки за посиланнями з неї.",
        "Так собі. В деяких питаннях ви орієнтуєтеся, але в умовах повномасштабної війни краще все ж бути відмінником. Так ви зможете убезпечити і себе, і людей навколо.",
        "Добре! Ви блискуче знаєте, як треба діяти під час повномасштабного вторгнення. Людям навколо вас має бути спокійніше поруч з таким експертом.",
        "Ідеально! Здається, ви унікальний синтез Зеленського, Залужного, Кіма, Маляр і Подоляка. А, можливо, в деяких питаннях їм взагалі варто просити у вас поради!",
      ],
    };
  },
  computed: {
    ...mapGetters("modal", ["getModalProps"]),
    ...mapGetters(["getActiveGame", "getActiveRound", "getSecurityPoints"]),
  },
  created() {},
  methods: {
    next() {
      this.$store.commit("SET_ACTIVE_GAME", 0);
      this.$store.commit("SET_ACTIVE_ROUND", 0);
      this.$store.commit("SET_SECURITY_POINTS", 0);
      this.$store.commit("SET_SECURITY_POINTS_ROUND", 0);
      this.$router.push({ name: "home" });
      setToLS("game", "0-0-0-0");
      this.$store.commit("modal/SET_MODAL", {
        state: false,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4.8rem 3.6rem 6.4rem
  text-align: center
  @include m
    padding: 3.2rem 1.6rem


.modal__text
  margin-bottom: 3.2rem

input
  background: #FFFFFF
  box-shadow: inset 1px 2px 16px #F4F4F4
  padding: 1.6rem
  width: 100%
  max-width: 32rem
  margin-bottom: 3.2rem

.input::placeholder
  font-weight: 500
  font-size: 1.4rem
  color: $text-primary-color

.name__title
  font-weight: 700
  margin-bottom: 2.4rem

.modal__control
  width: 100%
  max-width: 32rem
  display: flex
  flex-direction: column
  gap: 1.6rem

.role__icon
  max-width: 6.4rem
  max-height: 6.4rem
  margin-bottom: 1.6rem

.security__number
  margin-bottom: 1.6rem
  & span
    margin-left: 0.8rem
    font-size: 2.4rem
    font-weight: 700

.social
  display: flex
  gap: 3.2rem
  justify-content: center

.facebook-icon, .twitter-icon
  max-width: 6.4rem
  max-height: 6.4rem
  @include m
    max-width: 4.2rem
    max-height: 4.2rem
</style>
