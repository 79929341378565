<template>
  <v-main data-app>
    <Modal />
    <router-view />
  </v-main>
</template>

<script>
import Modal from "@/elements/Modals/Modal.vue";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default {
  name: "App",
  components: {
    Modal,
  },
  data: () => ({
    //
  }),
  created() {
    this.$store.dispatch("refreshGameData", {
      nextRound: null,
      nextGame: null,
      securityPoints: null,
    });
  },
  methods: {},
};
</script>
