import Vue from "vue";
import Vuex from "vuex";
import modal from "./modules/modal";
import router from "@/router";

Vue.use(Vuex);
import { getFromLS, isLSHasItem, setToLS } from "@/library/helpers";

export default new Vuex.Store({
  modules: { modal },
  state: {
    activeGame: 0,
    activeRound: 0,
    securityPoints: 0,
    securityPointsRound: 0,
  },
  getters: {
    getActiveGame: (state) => state.activeGame,
    getActiveRound: (state) => state.activeRound,
    getSecurityPoints: (state) => state.securityPoints,
    getSecurityPointsRound: (state) => state.securityPointsRound,
  },
  mutations: {
    SET_ACTIVE_GAME(state, payload) {
      state.activeGame = payload;
    },
    SET_ACTIVE_ROUND(state, payload) {
      state.activeRound = payload;
    },
    SET_SECURITY_POINTS(state, payload) {
      state.securityPoints = payload;
    },
    SET_SECURITY_POINTS_ROUND(state, payload) {
      state.securityPointsRound = payload;
    },
  },
  actions: {
    refreshGameData({ commit }, data) {
      let gameData;
      if (isLSHasItem("game")) {
        gameData = getFromLS("game");
      } else {
        gameData = "0-0-0-0";
      }
      let gameDataArray = gameData.split("-");
      if (
        gameDataArray.length !== 4 ||
        gameDataArray[0] > 2 ||
        gameDataArray[0] < 0 ||
        gameDataArray[1] > 4 ||
        gameDataArray[1] < 0 ||
        gameDataArray[2] > 150 ||
        gameDataArray[2] < 0 ||
        gameDataArray[3] > 50 ||
        gameDataArray[3] < 0
      ) {
        console.log("here");
        gameDataArray = [0, 0, 0, 0];
        router.push({ name: "home" });
      }
      gameDataArray = gameDataArray.map(Number);
      if (data.nextRound) {
        gameDataArray[1]++;
        commit("modal/SET_MODAL", {
          state: false,
        });
      }
      if (gameDataArray[1] == 5) {
        gameDataArray[1] = 0;
        gameDataArray[0]++;
        commit("modal/SET_MODAL", {
          name: "role-end",
          state: true,
        });
        router.push({ name: "role-select" });
      }
      if (gameDataArray[0] == 3) {
        commit("modal/SET_MODAL", {
          name: "results",
          state: true,
        });
      }
      if (data.securityPoints) {
        gameDataArray[2] += data.securityPoints;
        gameDataArray[3] += data.securityPoints;
      }
      commit("SET_ACTIVE_GAME", gameDataArray[0]);
      commit("SET_ACTIVE_ROUND", gameDataArray[1]);
      commit("SET_SECURITY_POINTS", gameDataArray[2]);
      commit("SET_SECURITY_POINTS_ROUND", gameDataArray[3]);
      gameData = [
        gameDataArray[0],
        gameDataArray[1],
        gameDataArray[2],
        gameDataArray[3],
      ].join("-");
      setToLS("game", gameData);
    },
  },
});
