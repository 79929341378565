<template>
  <div class="modal__role">
    <div class="modal__content">
      <!-- <h3 class="modal__title">Title</h3> -->
      <p
        v-html="
          tasks[`role_${getActiveGame}`][`question_${getActiveRound + 1}`][
            `option_${getModalProps.number}`
          ].result
        "
        class="modal__text"
      ></p>
      <p class="security__number">
        Рівень безпеки:
        <span
          >+{{
            tasks[`role_${getActiveGame}`][`question_${getActiveRound + 1}`][
              `option_${getModalProps.number}`
            ].scores
          }}</span
        >
      </p>
      <ButtonBase @click="next" class="modal__control"> Зрозуміло </ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tasks from "@/library/tasks.json";

export default {
  data() {
    return {
      tasks,
    };
  },
  computed: {
    ...mapGetters("modal", ["getModalProps"]),
    ...mapGetters(["getActiveGame", "getActiveRound", "getSecurityPoints"]),
  },
  created() {},
  methods: {
    next() {
      this.$store.dispatch("refreshGameData", {
        nextRound: true,
        nextGame: null,
        securityPoints:
          this.tasks[`role_${this.getActiveGame}`][
            `question_${this.getActiveRound + 1}`
          ][`option_${this.getModalProps.number}`].scores,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4.8rem 3.6rem 6.4rem
  text-align: center
  @include m
    padding: 3.2rem 1.6rem


.modal__text
  margin-bottom: 3.2rem

input
  background: #FFFFFF
  box-shadow: inset 1px 2px 16px #F4F4F4
  padding: 1.6rem
  width: 100%
  max-width: 32rem
  margin-bottom: 3.2rem

.input::placeholder
  font-weight: 500
  font-size: 1.4rem
  color: $text-primary-color

.name__title
  font-weight: 700
  margin-bottom: 2.4rem

.modal__control
  width: 100%
  max-width: 32rem

.role__icon
  max-width: 6.4rem
  max-height: 6.4rem
  margin-bottom: 1.6rem

.security__number
  margin-bottom: 1.6rem
  & span
    margin-left: 0.8rem
    font-size: 2.4rem
    font-weight: 700
</style>
