import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import SvgIcon from "@/elements/SvgIcon.vue";
import ButtonBase from "@/elements/ButtonBase.vue";
import vuetify from "./plugins/vuetify";
import "@/assets/sass/main.sass";
import VueSocialSharing from "vue-social-sharing";

Vue.component("svg-icon", SvgIcon);
Vue.component("ButtonBase", ButtonBase);
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
