<template>
  <v-dialog v-model="getModalState" persistent :max-width="`${modalWidth}px`">
    <div class="modal modal__container">
      <div class="modal__container_content">
        <component :is="modalName"></component>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import RoleSelectModal from "@/elements/Modals/RoleSelectModal.vue";
import AnswerModal from "@/elements/Modals/AnswerModal.vue";
import ResultsModal from "@/elements/Modals/ResultsModal.vue";
import RoleEndModal from "@/elements/Modals/RoleEndModal.vue";

export default {
  components: {
    RoleSelectModal,
    AnswerModal,
    ResultsModal,
    RoleEndModal,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters("modal", ["getModalState", "getModalName", "getModalProps"]),
    modalName() {
      return this.getModalName ? `${this.getModalName}-modal` : "";
    },
    modalWidth() {
      switch (this.getModalName) {
        case "role-select":
          return 800;
        default:
          return 800;
      }
    },
  },

  watch: {
    getModalState(value) {
      if (value) {
        document.body.classList.add("locked");
      } else {
        document.body.classList.remove("locked");
      }
    },
  },

  methods: {
    closeModal() {
      this.getModalName !== "pending"
        ? this.$store.commit("modal/SET_MODAL", {
            state: false,
          })
        : null;
    },
  },
};
</script>
<style lang="sass">
.modal__container
  position: relative
  padding: 3.2rem
  @include m
    padding: 3.2rem 1.6rem

.modal__container_content
  // background-color: var(--white)
  @include m
    position: relative

.modal__content
  display: flex
  flex-direction: column
  align-items: center
  background: $white
  overflow-y: auto

  // @include ms
  //   min-height: calc(100vh - 62px)
  //   justify-content: center
  //   padding: 2.4rem 1.6rem !important

.modal-cross__container
  position: absolute
  top: 0
  right: 3.2rem
  transform: translate(50%, 0)
  z-index: 202
  background: var(--button-close-modal-color)
  box-shadow: var(--box-shadow-primary)
  border-radius: 100px
  display: flex
  padding: 2.3rem
  cursor: pointer
  & svg
    max-width: 1.8rem
    max-height: 1.8rem
  @include m
    top: 1rem
    right: 3rem
    padding: 1.8rem
    & svg
      max-width: 1.3rem
      max-height: 1.3rem
</style>
