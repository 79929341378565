<template>
  <button
    :class="cssClasses"
    class="btn"
    :disabled="disabled"
    @click="clickHandler"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    card: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    cssClasses() {
      return {
        "disabled-btn": this.disabled,
        "card-btn": this.card,
      };
    },
  },
  methods: {
    clickHandler() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="sass">
.button-wrapper
  position: relative

.btn
  display: flex
  align-items: center
  justify-content: center
  position: relative
  padding: 15px 40px
  background-color: $green-main
  color: $white
  font-size: 14px
  line-height: 18px
  font-weight: 500
  cursor: pointer
  transition: $transition-speed
  border: 0
  @include m
    font-size: 14px
    line-height: 16px
    padding: 14px 32px

  &:hover
    background-color: $green-dark

.btn.card-btn
  &:hover
    filter: none

.disabled-btn
  opacity: 0.5
  cursor: default
  &:hover
    background-color: $green-main
</style>
