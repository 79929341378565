<template>
  <div class="modal__role">
    <div class="modal__content">
      <!-- <h3 class="modal__title">Title</h3> -->
      <p class="modal__text">{{ description[getModalProps.id] }}</p>
      <img class="role__icon" :src="`./role_${getModalProps.id}.png`" alt="" />
      <p v-if="!usernameFromLS" class="modal__text name__title">
        ЯК ТЕБЕ ЗВАТИ
      </p>
      <p v-else class="modal__text name__title">{{ usernameFromLS }}</p>
      <input
        maxlength="15"
        v-model="username"
        v-if="!usernameFromLS"
        placeholder="Ім'я"
      />
      <ButtonBase
        :disabled="!username && !usernameFromLS"
        @click="startGame(getModalProps.id)"
        class="modal__control"
      >
        Почати гру
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getFromLS, isLSHasItem, setToLS } from "@/library/helpers";

export default {
  data() {
    return {
      username: "",
      usernameFromLS: "",
      description: [
        "Кожен із нас — громадянин чи громадянка. Давайте перевіримо, наскільки грамотно ви будете діяти під час війни, та дізнаємося важливі речі про особисту безпеку.",
        "Шукати, знаходити, відправляти туди, куди треба — це все надзвичайно важливо. Але і тут треба не забувати про правила безпеки. Давайте дізнаємося про це більше.",
        "У 2022 волонтерство стало чи не головним заняттям усіх українців і українок. Втім, з великою силою приходить і велика відповідальність. Давайте перевіримо, чи готові ви до неї.",
      ],
    };
  },
  computed: {
    ...mapGetters("modal", ["getModalProps"]),
  },
  created() {
    isLSHasItem("username")
      ? (this.usernameFromLS = getFromLS("username"))
      : (this.usernameFromLS = "");
  },
  methods: {
    startGame() {
      this.$store.commit("modal/SET_MODAL", {
        state: false,
      });
      if (this.username) setToLS("username", this.username);
      this.$router.push({ name: "game" });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4.8rem 3.6rem 6.4rem
  text-align: center
  @include m
    padding: 3.2rem 1.6rem

.modal__text
  margin-bottom: 3.2rem

input
  background: #FFFFFF
  box-shadow: inset 1px 2px 16px #F4F4F4
  padding: 1.6rem
  width: 100%
  max-width: 32rem
  margin-bottom: 3.2rem

.input::placeholder
  font-weight: 500
  font-size: 1.4rem
  color: $text-primary-color

.name__title
  font-weight: 700
  margin-bottom: 2.4rem

.modal__control
  width: 100%
  max-width: 32rem

.role__icon
  max-width: 6.4rem
  max-height: 6.4rem
  margin-bottom: 1.6rem
</style>
